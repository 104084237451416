<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <section>
                <ngx-spinner size="medium" [type]="typeSelected">
                </ngx-spinner>
                <div>



                    <!-- Name Field -->
                    <hr>
                    <form [formGroup]="setReminderForm" class="reminder-form">
                        <div class="reminder-settings px-3">
                            <h6 class="fw-semibold mb-3">Reminder settings:</h6>
                            <p class="mb-2">
                                This setting adjusts the frequency of the reminders sent by Engage after sending the
                                letters for signing.
                            </p>

                            <!-- Reminder Dropdown -->
                            <div class="form-group d-flex gap-3 align-items-center mb-2">
                                <label for="reminder">Send reminder every : </label>
                                <ng-select class="Reminder-dropdown" formControlName="reminder"
                                    [items]="reminderOptions" bindLabel="label" bindValue="value"
                                    placeholder="Select reminder days" [(ngModel)]="selectedReminderId"
                                    (change)="onReminderDaysChange($event)" [searchable]="false" [clearable]="false">
                                </ng-select>
                                <p class="mb-0">Week Days</p>
                            </div>

                            <!-- Current and Reminder Dates -->
                            <div *ngIf="selectedReminderId === 0">
                                <p><span class="err-color">Note: </span>Automatic sending of reminders is disabled at
                                    the moment.</p>
                            </div>

                            <div *ngIf="selectedReminderId">
                                <p><span class="err-color">Note: </span>You have selected {{selectedReminderId}} days as
                                    the reminder
                                    interval.</p>
                                <p> <span class="fw-semibold"> Example</span>, if the letter was sent on <span
                                        class="fw-semibold">{{ currentDateFormatted
                                        }},</span> the
                                    reminder will sent
                                    on <span class="fw-semibold">{{ reminderDateFormatted }} 00:00 hrs </span>Eastern
                                    Time.
                                </p>
                            </div>
                            <hr>
                            <!-- Submit Button -->
                            <div class="mt-3 mt-md-3 text-end ">
                                <button type="button" (click)="discardReminderModal()"
                                    class="btn p-btn-outline">Discard</button>
                                <button type="button" [disabled]="reminderButtonDisable" (click)="submitReminder()"
                                    class="btn p-btn ms-3"> Save Reminder</button>
                            </div>
                        </div>
                    </form>
                </div>

            </section>
        </main>
    </div>
</section>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="error">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" (click)="closeSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Alert Reminder modal -->
<div class="modal" tabindex="-1" id="displayDiscardReminderModal" [ngStyle]="{'display':displayDiscardReminderModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayDiscardReminderModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Discard Changes</h5>
                <button type="button" (click)="onCloseDiscardReminderModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{reminderPopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onCloseDiscardReminderModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button type="button" (click)="discardReminder()" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<div [innerHTML]="trustedHtml"></div>