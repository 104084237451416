import { Component, OnInit, Renderer2, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockService } from 'src/app/services/block.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-remindersetting',
  templateUrl: './remindersetting.component.html',
  styleUrls: ['./remindersetting.component.css']
})
export class RemindersettingComponent implements OnInit {

  //common variables
  ScreenName: string = 'Engage Application Settings';
  typeSelected: string;
  trustedHtml: SafeHtml;
  errorMessage: string;
  displayErrorModal = "none";
  displaySuccessModal = "none";
  successMessage: string;
  secondPopupOpen: boolean = false;
  displayDiscardReminderModal: any;
  reminderPopupMessage: any;
  //
  reminderResponse: any;
  setReminderForm: FormGroup;
  fetchedName: string = 'John Doe';  // Example name, this should come from API
  fetchedDayString: string = '';
  fetchedDay: number = 0;
  reminderButtonDisable: boolean = true;
  selectedReminderString: string = '';
  selectedReminderId: number;

  reminderOptions = [
    { label: 'Zero', value: 0 }, // Add option for 0
    { label: 'One', value: 1 },
    { label: 'Two', value: 2 },
    { label: 'Three', value: 3 },
    { label: 'Four', value: 4 },
    { label: 'Five', value: 5 },
    { label: 'Six', value: 6 },
    { label: 'Seven', value: 7 },
  ];

  dayStringToNumberMap: { [key: string]: number } = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
  };

  dayNumberToStringMap: { [key: number]: string } = {
    0: 'Zero',
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven'
  };

  currentDateFormatted: string = '';
  reminderDateFormatted: string = '';

  //view child
  @ViewChild('maintoggledrop', { static: false }) maintoggledrop: ElementRef;

  constructor(private renderer: Renderer2, public SpinnerService: NgxSpinnerService, public _sanitizer: DomSanitizer, private formbld: FormBuilder, private cd: ChangeDetectorRef, public blockservice: BlockService, private router: Router) {
    this.typeSelected = 'timer';
    // set Reminder form
    this.setReminderForm = this.formbld.group({
      reminder: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.SpinnerService.show();
    this.fetchReminderValues();
  }
  addClassInDashboard(content: string) {
    if (content == 'sidebar-open') {
      this.renderer.addClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    } else {
      this.renderer.removeClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    }
  }
  public addDiv() {
    this.trustedHtml = this._sanitizer.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }
  fetchReminderValues() {
    this.SpinnerService.show();
    this.blockservice.getReminderSettingData()?.then((res) => {
      if (res.StatusCode == 200) {
        this.reminderResponse = res.Data[0];
        this.fetchedDayString = this.reminderResponse.ReminderValue;
        this.fetchedDay = this.reminderResponse.ReminderValueId;
        this.selectedReminderString = this.fetchedDayString;
        this.selectedReminderId = this.fetchedDay;
        if (this.selectedReminderId) {
          this.calculateReminderDate(this.selectedReminderId);
        }
        setTimeout(() => {
          this.SpinnerService.hide();
        }, 1000);
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400 || res.status == 400 || res.status == 401) {
        this.SpinnerService.hide();
      }
      else if (res.StatusCode == 500) {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }

    });
  }
  calculateReminderDate(days: number) {
    const currentDate = new Date();
    this.currentDateFormatted = this.formatDateWithoutTime(currentDate);

    let reminderDate = new Date(currentDate.getTime());

    let daysToAdd = days;

    // Loop to add only weekdays
    while (daysToAdd > 0) {
      reminderDate.setDate(reminderDate.getDate() + 1);  // Move to the next day

      // Check if the new day is a weekday (Monday-Friday)
      if (reminderDate.getDay() !== 6 && reminderDate.getDay() !== 0) {  // 6 = Saturday, 0 = Sunday
        daysToAdd--;  // Only decrement the daysToAdd if it's a weekday
      }
    }

    this.reminderDateFormatted = this.formatDateWithoutTime(reminderDate);
  }

  formatDateWithoutTime(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
    };
    return `${date.toLocaleDateString('en-US', options)}`;
  }

  // Handle selection change from the dropdown
  onReminderDaysChange(event: any) {
    this.selectedReminderId = event.value;
    if (this.selectedReminderId === 0) {
      this.selectedReminderString = this.dayNumberToStringMap[this.selectedReminderId];
      this.reminderButtonDisable = false;
      this.reminderDateFormatted = null; // Clear reminder date
      return;
    }
    this.reminderButtonDisable = false;
    this.selectedReminderString = this.dayNumberToStringMap[this.selectedReminderId];
    this.calculateReminderDate(this.selectedReminderId);
  }
  // Submit the reminder
  submitReminder() {
    if (this.setReminderForm.valid) {
      const ReminderSetting = {
        ReminderSettingId: 0,
        ReminderValue: this.selectedReminderString,
        ReminderValueId: this.selectedReminderId
      };
      this.SpinnerService.show();
      this.blockservice.postUpdateReminderSetting(ReminderSetting)?.then((res) => {
        this.SpinnerService.hide();
        if (res.StatusCode == 200) {
          this.selectedReminderId = res.Data.ReminderValueId;
          this.successMessage = 'Automatic reminder sending has been successfully disabled.';
          if (this.selectedReminderId) {
            this.successMessage = "Reminder for the Request for Signature  will be sent to the clients through Engage for every selected interval on weekdays."
          }
          //this.setReminderForm.reset();
          this.displaySuccessModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else if (res.StatusCode == 401 || res.StatusCode == 400 || res.status == 400 || res.status == 401) {
          this.SpinnerService.hide();
        }
        else if (res.StatusCode == 500) {
          this.SpinnerService.hide();
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else {
          this.SpinnerService.hide();
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
    }
  }

  closeErrorModal() {
    this.displayErrorModal = "none";
    if (this.secondPopupOpen == true) {
      this.secondPopupOpen = false;
    }
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
  closeSuccessModal() {
    this.displaySuccessModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
    this.router.navigate(["/engagementletterdashboard"]);
  }
  discardReminderModal() {
    this.reminderPopupMessage = 'Any changes made will not be saved.By clicking "Yes" you will be returned to the letter dashboard.'
    this.displayDiscardReminderModal = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
  }
  onCloseDiscardReminderModal() {
    this.displayDiscardReminderModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
  discardReminder() {
    this.displayDiscardReminderModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
    this.router.navigate(["/engagementletterdashboard"]);
  }
}
